//Literal Typeのみ定数として扱う
export type UserType = 'admin' | 'company' | 'seller';
export type AdminUserRole = keyof typeof AdminUserRoleText;
export type OutsourcerPermissionCodes = keyof typeof OutsourcerPermissionCodesText;
export type FloorPlanRadioType = 'input' | 'other' | 'unknown';

export const postalRegex: RegExp = /^$|^\d{7}$|^\d{3}-\d{4}$/;
export const emailRegex: RegExp = /^[A-Za-z0-9]{1}[A-Za-z0-9_.+-]*@{1}[A-Za-z0-9_.-]{1,}\.[A-Za-z]{1,}$/;
export const floatNumberRegex: RegExp = /^([1-9]\d*|0)(\.\d{1,4})?$/;
export const integerNumberRegex: RegExp = /^[0-9]*$/;
export const positiveIntegerRegex: RegExp = /^[1-9][0-9]*$/;
export const imageFileRegex: RegExp = /\.(gif|jpe?g|tiff?|png|webp|bmp)$/i;

export const consumptionTax = 1.1;

export const DefaultIqraLatLong = { lat: 34.724, long: 135.5 };

export const AdminUserRoleText = {
  master: 'マスター',
  manager: 'マネージャー',
  member: 'メンバー',
  outsourcer: '業務委託',
};

export const OutsourcerPermissionCodesText = {
  iqra_cloud: 'イクラウド入力',
  sale_case_csv: 'CSV整形',
  sale_case_approval: '実績承認',
};

export const MediaQuery = {
  ssm: '(max-width:375px)',
  sm: '(max-width:576px)',
  md: '(max-width:768px)',
  lg: '(max-width:992px)',
  xl: '(max-width:1239px)',
  xxl: '(max-width:1400px)',
};

export const MediaQueryMin = {
  ssm: '(min-width:375px)',
  sm: '(min-width:576px)',
  md: '(min-width:768px)',
  lg: '(min-width:992px)',
  xl: '(min-width:1239px)',
  xxl: '(min-width:1400px)',
};

export const availableMenuList: { [key in 'master' | 'manager' | 'member']: string[] } = {
  master: [
    'dashboard',
    'company',
    'sale_cases',
    'announcements',
    'mansion',
    'city',
    'town',
    'contents_csv',
    'account',
    'talk',
    'properties',
    'seller',
    'company_contact',
    'seller_contact',
    'agreement_reports',
    'iqra_cloud/top',
    'iqra_cloud/list',
    'iqra_cloud/upload',
    'iqra_cloud/company',
    'iqra_cloud/map',
  ],
  manager: [
    'dashboard',
    'company',
    'sale_cases',
    'announcements',
    'mansion',
    'city',
    'town',
    'contents_csv',
    'talk',
    'properties',
    'seller',
    'company_contact',
    'seller_contact',
    'agreement_reports',
    'iqra_cloud/top',
    'iqra_cloud/list',
    'iqra_cloud/upload',
    'iqra_cloud/company',
    'iqra_cloud/map',
  ],
  member: [
    'dashboard',
    'company',
    'sale_cases',
    'announcements',
    'mansion',
    'city',
    'town',
    'talk',
    'properties',
    'seller',
    'company_contact',
    'seller_contact',
    'agreement_reports',
    'iqra_cloud/top',
    'iqra_cloud/list',
    'iqra_cloud/upload',
    'iqra_cloud/company',
    'iqra_cloud/map',
  ],
};

export const outsourcerAvailableMenuList: { [key in OutsourcerPermissionCodes]: string[] } = {
  iqra_cloud: ['iqra_cloud/top', 'iqra_cloud/list'],
  sale_case_csv: ['city', 'town', 'mansion'],
  sale_case_approval: ['sale_cases'],
};

export const roleBaseRestrictedRoutes: { [key in AdminUserRole]: string[] } = {
  master: [],
  manager: ['/console/admin/account'],
  member: ['/console/admin/contents_csv', '/console/admin/account'],
  outsourcer: [
    '/console/admin/company',
    '/console/admin/announcements',
    '/console/admin/account',
    '/console/admin/chat',
    '/console/admin/agreement_reports',
    '/console/admin/iqra_cloud/jusetsu/upload',
    '/console/admin/iqra_cloud/companies',
    '/console/admin/iqra_cloud/jusetsu/map',
  ],
};

export const OutsourcerPermittedRoutes: { [key in OutsourcerPermissionCodes]: string[] } = {
  iqra_cloud: ['/console/admin/dashboard', '/console/admin/iqra_cloud', '/console/admin/iqra_cloud/jusetsu/list'],
  sale_case_csv: [
    '/console/admin/dashboard',
    '/console/admin/city',
    '/console/admin/town',
    '/console/admin/mansion',
    '/console/admin/sale_cases/test_import',
  ],
  sale_case_approval: ['/console/admin/dashboard', '/console/admin/sale_cases'],
};

const MapZoomList = [
  { 北海道: 7 },
  { 青森県: 8 },
  { 岩手県: 8 },
  { 宮城県: 8.5 },
  { 秋田県: 8 },
  { 山形県: 8 },
  { 福島県: 8 },
  { 茨城県: 8 },
  { 栃木県: 8 },
  { 群馬県: 8 },
  { 埼玉県: 9 },
  { 千葉県: 9 },
  { 東京都: 10 },
  { 神奈川県: 9.5 },
  { 新潟県: 8 },
  { 富山県: 8 },
  { 石川県: 8.5 },
  { 福井県: 8.5 },
  { 山梨県: 8 },
  { 長野県: 8 },
  { 岐阜県: 9 },
  { 静岡県: 8.5 },
  { 愛知県: 9 },
  { 三重県: 8.5 },
  { 滋賀県: 9 },
  { 京都府: 9 },
  { 大阪府: 10 },
  { 兵庫県: 9 },
  { 奈良県: 9 },
  { 和歌山県: 8 },
  { 鳥取県: 9 },
  { 島根県: 8 },
  { 岡山県: 8.5 },
  { 広島県: 8.5 },
  { 山口県: 8.5 },
  { 徳島県: 8 },
  { 香川県: 9 },
  { 愛媛県: 9 },
  { 高知県: 8.5 },
  { 福岡県: 9 },
  { 佐賀県: 9.5 },
  { 長崎県: 9.5 },
  { 熊本県: 8 },
  { 大分県: 8.5 },
  { 宮崎県: 8.5 },
  { 鹿児島県: 8.5 },
  { 沖縄県: 9 },
];

export const zoomRate = (prefectureCode: string) => Object.values(MapZoomList[Number(prefectureCode) - 1])[0];

export const AFFILIATED_COMPANIES_NUMBER = 3800;
